
import { commonProps } from '~/utils/prismic';

export default {
  components: {
    FormBlock: () => import('../form-block'),
    ConfirmationBlock: () => import('../confirmation-block'),
  },
  props: commonProps,
  data() {
    return {
      confirmationScreen: false,
    };
  },
  methods: {
    handleFormSuccess() {
      this.confirmationScreen = true;
    },
  },
};
